/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
    position: relative;
	margin-bottom: 0px!important;
    &:before {
        content: '';
        height: 125px;
        background: linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0));
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
	.slick-slide {
        height: 100vh;
		overflow: hidden;
		position: relative;
		outline: none;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.1);
        }
		img {
			display: block;
			width: 100%;
			height: 100%;
            object-fit: cover;
            object-position: center center;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 50%;
			text-align: center;
			color: #fff;
            padding: 0px 15px;
            margin: 0 auto;
            transform: translateY(-50%);
            z-index: 1;
            .slider__title {
                display: block;
                font-family: $medium;
                font-size: 36px;
                color: #ffffff;
                text-transform: uppercase;
                animation-name: fadeOut;
            }
		}
        &.slick-active {
            .slider__block {
                .slider__title {
                    animation-name: fadeInUp!important;
                    animation-delay: 1s;
                }
            }
        }
	}
    .slick-arrow {
        width: auto;
        height: auto;
        z-index: 1;
        &:before {
            display: none;
        }
        &:after {
            content: '';
            font-family: 'fontAwesome';
            font-size: 60px;
            color: #fff;
        }
        &.slick-prev {
            left: 35px;
            &:after {
                content: '\f104';
            }
        }
        &.slick-next {
            right: 35px;
            &:after {
                content: '\f105';
            }
        }
    }
	&.dot {
        .slick-dots {
            width: auto;
            right: auto;
            left: 50%;
            bottom: 190px;
            margin-left: -575px;
            li {
                button {
                    &:after {
                        background-color: #fff;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        &.dot {
            .slick-dots {
                bottom: 120px;
            }
        }
    }
    @media screen and (max-width: 1366px) {
        &.dot {
            .slick-dots {
                bottom: 80px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        &.dot {
            .slick-dots {
                bottom: 50px;
                left: 15px;
                right: 15px;
                margin: 0;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-slide {
            height: 400px;
            .slider__block {
                .slider__title {
                    font-size: 28px;
                }
            }
        }
        &:before {
            display: none;
        }
        &.dot {
            .slick-dots {
                bottom: 25px;
            }
        }
        .slick-arrow {
            display: none!important;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: 350px;
            .slider__block {
                .slider__title {
                    font-size: 24px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .slick-slide {
            height: 300px;
            .slider__block {
                .slider__title {
                    font-size: 20px;
                }
            }
        }
    }
}
.dot {
    .slick-dots {
        bottom: 0px;
        li {
            width: auto;
            height: auto;
            margin: 0px 5px;
            button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                border: 2px solid transparent;
                padding: 0;
                transition: 300ms;
                &:before {
                    display: none;
                }
                &:after {
                    content: '';
                    width: 8px;
                    height: 8px;
                    background-color: #3962dc;
                    border-radius: 100%;
                }
                &:hover {
                    border: 2px solid #3962dc;
                }
            }
            &.slick-active {
                button {
                    border: 2px solid #3962dc;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-dots {
            li {
                button {
                    width: 15px;
                    height: 15px;
                    &:after {
                        width: 5px;
                        height: 5px;
                    }
                }
            }
        }
    }
}
/* main slider */

/* main catalog */
.bg {
    position: relative;
    &:before {
        content: '';
        background-color: rgba(255,255, 255, 0.9);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
    > img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
        z-index: -2;
    }
    &.bg--blue {
        color: #fff;
        &:before {
            background-color: rgba(19,61,124, 0.9)
        }
        h1 {
            color: #fff;
        }
    } 
}
.cat {
    .btn {
        margin: 0 auto;
    }
    .container {
        width: auto;
        padding-left: 30px;
        padding-right: 30px;
        max-width: 1900px;
    }
}
.cat__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -30px 0 -12px;
    .cat__item {
        width: calc(20% - 60px);
        margin: 0 30px 30px;
        position: relative;
        &:before {
            content: '';
            width: 3px;
            height: 255px;
            background-color: #3a64e1;
            position: absolute;
            top: 50px;
            left: -18px;
            transition: 300ms;
        }
        &:after {
            content: '';
            width: 60px;
            height: 3px;
            background-color: #3a64e1;
            position: absolute;
            top: 304px;
            left: -18px;
        }
        .cat__img {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 250px;
            background-color: #fff;
            position: relative;
            overflow: hidden;
            padding: 10px;
            border: 1px solid #e1e1e1;
            img {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        .cat__text {
            display: flex;
            height: 100%;
            align-items: center;
            text-align: center;
            color: #fff;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            background-color: rgba(0, 45, 114, 0.7);
            padding: 15px;
            transition: 300ms;
        }
        h3 {
            height: 66px;
            display: flex;
            align-items: center;
            font-family: $regular;
            font-size: 20px;
            color: #414141;
            margin: 20px 0 0 60px;
            line-height: 1.1;
            transition: 300ms;
        }
        &:hover {
            &:before {
                height: 305px;
                top: 0;
            }
            .cat__text {
                top: 0;
            }
            h3 {
                color: #002d72;
            }
        }
    }
    @media screen and (max-width: 1680px) {
        .cat__item {
            width: calc(20% - 60px);
            .cat__img {
                height: 220px;
            }
            &:after {
                width: 30px;
                top: 271px;
            }
            &:before {
                height: 222px;
            }
            h3 {
                font-size: 20px;
                margin-left: 30px;
            }
            &:hover {
                &:before {
                    height: 271px;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .cat__item {
            width: calc(20% - 30px);
            margin-left: 15px;
            margin-right: 15px;
            margin-bottom: 20px;
            &:before {
                height: 170px;
                left: -10px;
            }
            &:after {
                width: 30px;
                top: 220px;
                left: -10px;
            }
            .cat__img {
                height: 180px;
            }
            h3 {
                height: 55px;
                font-size: 16px;
                margin-left: 40px;
                margin-top: 15px;
            }
            .cat__text {
                font-size: 14px;
            }
            &:hover {
                &:before {
                    height: 220px;
                }
            }
        }
    }
    @media screen and (max-width: 1366px) { 
        .cat__item {
            margin-bottom: 15px;
            h3 {
                margin-top: 10px;
            }
            .cat__img {
                height: 140px;
            }
            .cat__text {
                font-size: 12px;
            }
            &:before {
                height: 127px;
            }
            &:after {
                top: 176px;
            }
            &:hover {
                &:before {
                    height: 176px;
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        margin: 0 -15px 0 0;
        .cat__item {
            h3 {
                height: 52px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .cat__item {
            width: calc(25% - 30px);
            margin-bottom: 20px;
            .cat__img {
                height: 160px;
            }
            &:before {
                height: 150px!important;
                left: -10px;
                top: 50px!important;
            }
            &:after {
                left: -10px;
                top: 198px;
                width: 10px;
            }
            h3 {
                text-align: center;
                margin: 10px 10px 0;
            }
            .cat__text {
                display: none;
                top: 0;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .cat__item {
            display: flex;
            flex-direction: column;
            width: calc(50% - 30px);
            h3 {
                flex: 1 0 auto;
                height: auto;
                text-align: center;
                justify-content: center;
            }
            &:before, &:after  {
                display: none;
            }
        }
    }
    @media screen and (max-width: 480px) {
        margin: 0;
        .cat__item {
            width: 100%;
            margin: 0 0 20px;
            &:before, &:after {
                display: none;
            }
            .cat__text {
                display: none;
            }
        }
    }
}
/* main catalog */

/* main about */
.about {
    display: flex;
    .left {
        width: 50%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    .right {
        display: flex;
        width: 50%;
        align-items: center;
        padding: 25px 0px 25px 40px;
    }
    .about__block {
        width: 570px;
        h3 {
            font-size: 20px;
            margin: 20px 0;
        }
    }
    h1 {
        font-size: 34px;
        text-transform: uppercase;
        text-align: left;
        margin-bottom: 35px;
    }
    @media screen and (max-width: 1440px) {
        .about__block {
            h3 {
                font-size: 18px;
                margin: 15px 0;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .right {
            padding: 35px 15px 35px 50px;
        }
        .about__block {
            width: 100%;
            h3 {
                font-size: 16px;
            }
        }
        h1 {
            font-size: 28px;
            margin-bottom: 20px;
        }
    }
    @media screen and (max-width: 1024px) {
        h1 {
            font-size: 26px;
        }
        .right {
            padding: 30px 15px 30px 30px;
        }
    }
    @media screen and (max-width: 768px) {
        h1 {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 500px) {
        flex-wrap: wrap;
        .left, .right {
            width: 100%;
        }
        .right {
            order: 1;
            padding: 25px 15px;
        }
        .left {
            order: 2;
            img {
                height: auto;
            }
        }
    }
}
/* main about */

/* main partners */
.partners {
    padding: 150px 150px 130px;
    position: relative;
    overflow: hidden;
    .partners__slider {
        margin: 0 -50px;
        .slick-slide {
            display: flex;
            height: 100px;
            align-items: center;
            justify-content: center;
            outline: none;
            margin: 0 50px;
            img {
                max-height: 100%;
                max-width: 100%;
                object-fit: contain;
                object-position: center;
            }
        }
        &.dot {
            .slick-dots {
                bottom: -60px;
            }
        }
    }
    .partners__title {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        font-family: $black;
        font-size: 12vw;
        color: #f8f8f8;
        text-align: center;
        text-transform: uppercase;
        z-index: -1;
        transform: translateY(-55%);
    }
    @media screen and (max-width: 1440px) {
        padding: 125px 100px;
        .partners__slider {
            .slick-slide {
                margin: 0 30px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        padding: 100px 50px;
        .partners__title {
            font-size: 15vw;
        }
        .partners__slider {
            margin: 0;
            &.dot {
                .slick-dots {
                    bottom: -40px;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        padding: 50px 25px;
        .partners__slider {
            .slick-slide {
                margin: 0 15px;
            }
            &.dot {
                .slick-dots {
                    bottom: -20px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .partners__title {
            font-size: 17vw;
        }
        .partners__slider {
            .slick-slide {
                height: 75px;
            }
        }
    }
}
/* main partners */

/* main portfolio */
.portfolio__block {
    display: flex;
    flex-wrap: wrap;
    .portfolio__item {
        width: 33.33%;
        height: 500px;
        overflow: hidden;
        position: relative;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            transition: 300ms;
        }
        .portfolio__title {
            display: flex;
            background-color: rgba(0, 45, 114, 0.8);
            align-items: center;
            justify-content: center;
            font-size: 24px;
            text-align: center;
            color: #fff;
            padding: 20px 15px;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            transform: translateY(-50%);
            z-index: 1;
            transition: 300ms;
            opacity: 0;
        }
        &:hover {
            img {
                transform: scale(1.1);
            }
            .portfolio__title {
                opacity: 1;
            }
        }
    }
    @media screen and (max-width: 1440px) {
        .portfolio__item {
            height: 400px;
        }
    }
    @media screen and (max-width: 1366px) {
        .portfolio__item {
            height: 375px;
        }
    }
    @media screen and (max-width: 1024px) {
        .portfolio__item {
            height: 300px;
            .portfolio__title {
                font-size: 20px;
                padding: 15px;
                opacity: 1;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .portfolio__item {
            height: 250px;
            .portfolio__title {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .portfolio__item {
            height: 220px;
            .portfolio__title {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .portfolio__item {
            height: auto;
            width: 100%;
            .portfolio__title {
                font-size: 16px;
            }
            img {
                height: auto;
            }
        }
    }
}
/* main portfolio */

/* main news */
.news__block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .news__item {
        width: calc(50% - 40px);
        position: relative;
        margin: 18px 0 0;
        &:before {
            content: '';
            height: 2px;
            width: 203px;
            background-color: #002d72;
            position: absolute;
            top: -18px;
            left: -18px;
        }
        &:after {
            content: '';
            height: 100px;
            width: 2px;
            background-color: #002d72;
            position: absolute;
            top: -18px;
            left: -18px;
        }
        .date {
            display: block;
            color: #414141;
            margin-bottom: 15px;
        }
        .news__img {
            height: 330px;
            overflow: hidden;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        p {
            font-family: $bold;
            color: #333333;
            margin: 20px 0 0;
        }
        &:hover {
            p {
                color: #002d72;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .news__item {
            padding-left: 18px;
            &:before {
                left: 0;
            }
            &:after {
                left: 0;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .news__item {
            width: calc(50% - 20px);
            margin-bottom: 25px;
            &:before {
                top: -13px;
            }
            &:after {
                top: -13px;
            }
            .news__img {
                height: 300px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .news__item {
            width: calc(50% - 10px);
            .news__img {
                height: 230px;
            }
            p {
                margin-top: 15px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        .news__item {
            .news__img {
                height: 200px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .news__item {
            width: 100%;
            .news__img {
                height: auto;
            }
        }
    }
}
/* main news */

/* main content */