/* footer */
footer {
    .up {
        background: url(../img/img-21.jpg) no-repeat;
        background-size: cover;
        font-size: 18px;
        color: #fff;
        padding-top: 35px;
        padding-bottom: 45px;
        .container {
            display: flex;
            justify-content: space-between;
        }
        a {
            display: table;
            color: #fff;
        }
        h5 {
            color: #fff;
            text-transform: uppercase;
            margin: 0px 0px 25px 0;
        }
    }
    .footer__logo {
        img {
            display: block;
        }
    }
    .footer--cts {
        ul li {
            margin-bottom: 15px;
        }
    }
    .footer--search {
        width: 250px;
        p {
            font-size: 16px;
            margin: 20px 0 10px 0;
        }
    }
    .socials {
        li {
            @include inline-block;
            margin: 0px 10px 0 0;
        }
    }
    form {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #c5c6c6;
        padding: 2px 0;
        button {
            border: none;
            background-color: transparent;
            font-family: 'fontAwesome';
            font-size: 18px;
            color: #fff;
            padding: 0;
            outline: none;
            cursor: pointer;
            &:after {
                content: '\f002';
            }
        }
        input {
            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            font-family: $regular;
            font-size: 16px;
            color: #fff;
            padding: 5px 5px 5px 10px;
            appearance: none;
            &::placeholder {
                color: #fff;
            }
            &::-webkit-search-cancel-button {
                appearance: none;
            }
        }
    }
    ul {
        @include ul-default;
        li {
            margin: 13px 0;
            &.col--location,
            &.col--phone,
            &.col--email {
                &:after {
                    color: #fff;
                }
            }
            a {
                &:hover {
                    text-decoration: underline;
                }
            }
            &.active a {
                text-decoration: underline;
            }
        }
    }
    .down {
        display: flex;
        height: 80px;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: #878787;
        p {
            margin: 0;
        }
        a {
            color: #878787;
        }
        .artmedia {
            display: flex;
            align-items: center;
        }
    }
    @media screen and (max-width: 1200px) {
        .up {
            font-size: 16px;
        }
    }
    @media screen and (max-width: 1024px) {
        .up {
            font-size: 14px;
        }
        .down {
            height: 60px;
            font-size: 14px;
        }
        .footer--search {
            p {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .up {
            background: #002d71;
            padding-top: 20px;
            padding-bottom: 20px;
            .container {
                flex-wrap: wrap;
            }
            h5 {
                margin-bottom: 15px;
            }
        }
        .footer__logo {
            width: 100%;
            margin-bottom: 15px;
            a {
                display: table;
                margin: 0 auto;
            }
        }
        form {
            input {
                font-size: 14px;
            }
        }
        ul li {
            margin: 10px 0;
        }
    }
    @media screen and (max-width: 600px) {
        .footer__item {
            width: 100%;
            margin-bottom: 20px;
        }
        .footer--search {
            h5 {
                margin-bottom: 5px;
            }
        }
        .down {
            height: auto;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
            padding: 10px 15px;
        }
        .artmedia {
            margin-top: 5px;
        }
    }
}
/* footer */