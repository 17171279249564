/* page content */
.page {
    padding-top: 80px;
    padding-bottom: 80px;
	.news1, .news {
		.date {
			color: #002d72;
		}
        h2 {
            font-size: 20px;
            margin: 15px 0;
        }
	}
    .name {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .name__img {
            width: 300px;
            overflow: hidden;
            img {
                width: 100%;
            }
        }
        a {
            width: calc(100% - 300px);
            padding-left: 30px;
        }
    }
	.page_nav {
		.active {
			color: #002d72;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #002d72;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    p a {
        color: #002d72;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    h1 {
        font-size: 32px;
    }
    &.bg {
        > img {
            top: 50px;
            object-position: center top;
        }
    }
    .dwnl {
        font-size: 16px;
        float: right;
        color: #002d72;
        margin-top: -8px;
        &.btn {
            padding-left: 40px!important;
            padding-right: 40px!important
        }
    }
    @media screen and (max-width: 1440px) {
        h1 {
            margin-bottom: 30px;
        }
    }
    @media screen and (max-width: 1200px) {
        h1 {
            font-size: 32px;
        }
        .name {
            .name__img {
                width: 250px;
            }
            a {
                width: calc(100% - 250px);
            }
        }
    }
	@media screen and (max-width: 1024px) {
        padding-top: 0;
        padding-bottom: 50px;
		#photos-list td img {
			width: 100%;
			height: auto;
		}
        h1 {
            font-size: 28px;
        }
        &.bg > img {
            top: 0;
        }
        .news1, .news {
            h2 {
                font-size: 18px;
            }
        }
        .name {
            .name__img {
                width: 200px;
            }
            a {
                width: calc(100% - 200px);
                padding-left: 15px;
            }
        }
	}
    @media screen and (max-width: 768px) {
        padding-bottom: 30px;
        h1 {
            font-size: 24px;
        }
        .news1, .news {
            h2 {
                font-size: 16px;
            }
        }
        .name {
            .name__img {
                width: 150px;
            }
            a {
                width: calc(100% - 150px);
            }
        }
    }
    @media screen and (max-width: 600px) {
        .dwnl {
            float: none;
            margin: 15px 0 0;
        }
        .name {
            .name__img {
                width: 100px;
            }
            a {
                width: calc(100% - 100px);
            }
        }
    }
	@media screen and (max-width: 480px) {
		p img, p strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    font-weight: 500;
	font-size: 15px;
    line-height: 1;
    margin: 15px 0 30px;
	ul {
		@include ul-default;
		li {
			padding: 5px 0px;
			color: #002d72;
			@include inline-block;
			a {
				text-decoration: none;
                color: #414141;
                &:hover {
                    color: #002d72;
                }
			}
			&:after {
				content: '/';
				margin: 0px 2px 0px 5px;
				color: #414141;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
	}
    @media screen and (max-width: 1440px) {
        margin-top: 20px;
        margin-bottom: 30px;
    }
    @media screen and (max-width: 1366px) {
        margin-top: 10px;
        margin-bottom: 20px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        padding-top: 15px;
        margin: 0px 0 25px;
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
        text-align: left;
    }
}
/* breadcrumbs */

/* page about */
.page {
    .about {
        .right {
            align-items: flex-start;
            padding-left: 40px;
        }
        h1 {
            text-transform: inherit;
            margin-bottom: 30px;
        }
        .breadcrumbs {
            margin-bottom: 20px;
            margin-top: 0px;
        }
        .about__block {
            width: 570px;
        }
    }
    .partners {
        padding: 90px 150px;
        margin-bottom: -80px;
    }
    .partners__slider {
        &.dot {
            .slick-dots {
                bottom: -40px;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .about {
            .right {
                padding-left: 50px;
            }
            .breadcrumbs {
                margin-bottom: 20px;
            }
            h1 {
                margin-bottom: 20px;
            }
        }
        .partners {
            padding: 50px 100px;
            .partners__slider {
                &.dot {
                    .slick-dots {
                        bottom: -30px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .about {
            .right {
                padding-left: 30px;
            }
        }
        .partners {
            padding: 40px 50px;
            margin-bottom: -50px;
            .partners__slider {
                &.dot {
                    .slick-dots {
                        bottom: -20px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        .partners {
            padding-top: 30px;
            padding-bottom: 50px;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: -30px;
            .partners__slider {
                &.dot {
                    .slick-dots {
                        bottom: -30px;
                    }
                }
            }   
            .partners__title {
                top: 45%;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .about {
            .right {
                padding: 0px 15px 15px;
            }
            .breadcrumbs {
                margin-top: 0px;
                margin-bottom: 15px;
            }
        }
    }
}
/* page about */

/* page catalog */
.page {
    .cat__block {
        max-width: 1900px;
        padding: - 30px;
        margin: 0 auto;
        .cat__item:nth-last-child(-n+3) {
            margin-bottom: 25px;
            @media screen and (max-width: 768px) {
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .cat__block {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}
.pdf {
    display: table;
    position: relative;
    padding-left: 25px;
    &:after {
        content: '';
        width: 20px;
        height: 20px;
        background: url(../img/pdf.svg) no-repeat center left;
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
    }
    &:hover {
        text-decoration: underline;
    }
    @media screen and (max-width: 1024px) {
        padding-left: 20px;
        &:after {
            width: 15px;
            height: 15px;
        }
    }
}
.cat--inside {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    .cat--inside__item {
        width: calc(25% - 20px);
        background-color: #fff;
        text-align: center;
        padding: 15px;
        margin: 0 10px 20px;
        border: 1px solid #e1e1e1;
        line-height: 1;
        box-shadow: 0px 0px 20px rgba(0,0,0,0.3);
        .cat--inside__img {
            display: flex;
            height: 210px;
            align-items: center;
            justify-content: center;
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        h3 {
            font-size: 26px;
            margin: 8px 0;
            transition: 300ms;
        }
        .price {
            display: block;
            font-family: $bold;
            font-size: 26px;
            color: #353535;
            margin-bottom: 10px;
        }
        .art {
            display: table;
            width: 135px;
            position: relative;
            font-family: $medium;
            font-size: 16px;
            color: #555555;
            text-align: left;
            padding-left: 26px;
            margin: 0 auto 10px;
            &:after {
                content: '\f005';
                font-family: 'fontAwesome';
                font-size: 20px;
                color: #002d72;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);

            }
        }
        .cat--btn {
            display: flex;
            height: 40px;
            background-color: #002d72;
            font-size: 16px;
            color: #ffffff;
            align-items: center;
            justify-content: center;
            line-height: 1;
            padding-bottom: 5px;
            margin-top: 20px;
            transition: 300ms;
            &:hover {
                background-color: #3a64e1;
            }
        }
        &:hover {
            h3 {
                color: #002d72;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .cat--inside__item {
            h3 {
                font-size: 22px;
            }
            .price {
                font-size: 22px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .cat--inside__item {
            width: calc(33.33% - 20px);
        }
    }
    @media screen and (max-width: 680px) {
        .cat--inside__item {
            width: calc(50% - 20px);
        }
    }
    @media screen and (max-width: 480px) {
        margin: 0;
        .cat--inside__item {
            width: 100%;
            margin: 0 0 20px;
        }
    }
}

.bg--white {
    background-color: #fff;
    padding: 25px 90px 30px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
    @media screen and (max-width: 1200px) {
        padding: 25px 50px 25px;
    }
    @media screen and (max-width: 1024px) {
        padding: 20px 30px;
    }
    @media screen and (max-width: 768px) {
        padding: 20px 15px;
    }
}

.cat--unit {
    display: flex;
    flex-wrap: wrap;
    .left {
        width: 430px;
        padding-right: 90px;
        border-right: 2px solid #d2d2d2;
        margin-bottom: 35px;
    }
    .right {
        width: calc(100% - 430px);
        padding-left: 65px;
        padding-top: 70px;
        ul {
            @include ul-default;
            li {
                margin: 10px 0;
            }
        }
    }
    .price__title {
        display: block;
        font-family: $bold;
        margin: 50px 0;
        .price {
            display: block;
            font-size: 36px;
            color: #e31e24;
        }
    }
    @media screen and (max-width: 1200px) {
        .right {
            padding-left: 30px;
            padding-top: 20px;
        }
        .price__title {
            .price {
                font-size: 30px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .left {
            width: 375px;
            padding-right: 35px;
        }
        .right {
            width: calc(100% - 375px);
        }
        .price__title {
            margin: 20px 0;
            .price {
                font-size: 24px;
            }
        }
    }
    @media screen and (max-width: 680px) {
        .left {
            width: 100%;
            padding-right: 0;
            border: none;
            margin-bottom: 20px;
        }
        .right {
            width: 100%;
            padding: 0;
            margin-bottom: 20px;
        }
    }
}

.cat--unit__for {
    width: 330px;
    .slick-slide {
        height: 370px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }
    @media screen and (max-width: 680px) {
        margin: 0 auto;
    }
    @media screen and (max-width: 480px) {
        width: 100%;
        .slick-slide {
            height: 300px;
        }
    }
    @media screen and (max-width: 380px) {
        .slick-slide {
            height: 270px;
        }
    }
}

.cat--unit__nav {
    margin: 10px 0 0;
    .slick-slide {
        height: 72px;
        outline: none;
        margin: 0 5px;
        transition: 300ms;
        position: relative;
        cursor: pointer;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 3px solid #004bb9;
            opacity: 0;
            transition: 300ms;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        &:hover, &.slick-current {
            &:after {
                opacity: 1;
            }
        }
    }
    .slick-arrow {
        z-index: 1;
        top: 48%;
        &:before {
            display: none;
        }
        &:after {
            font-family: 'fontAwesome';
            font-size: 28px;
            color: #004bb9;
            line-height: 18px;
        }
        &.slick-prev {
            &:after {
                content: '\f104';
            }
        }
        &.slick-next {
            &:after {
                content: '\f105';
            }
        }
    }
    @media screen and (max-width: 680px) {
        padding: 0 20px;
        .slick-arrow {
            &.slick-prev {
                left: -5px;
            }
            &.slick-next {
                right: -5px;
            }
        }
    }
    @media screen and (max-width: 340px) {
        .slick-slide {
            height: 65px;
        }
    }
}

.similar {
    padding-top: 25px;
    h3 {
        margin-bottom: 0;
    }
}
.similar__slider {
    display: block;
    margin: 0!important;
    .slick-list {
        padding: 25px 100px!important;
    }
    .slick-slide {
        display: flex;
        height: auto;
        flex-direction: column;
        margin: 0 10px;
    }
    .slick-track {
        display: flex;
        align-items: stretch;
    }
    &.dot {
        .slick-dots {
            bottom: -30px;
        }
    }
    .cat--inside__item {
        .cat--btn {
            margin-top: 15px;
        }
    }
    @media screen and (max-width: 1200px) {
        .slick-list {
            padding: 25px 50px!important;
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-list {
            padding: 25px 5px!important;
        }
        &.dot {
            .slick-dots {
                bottom: -20px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        &.dot {
            .slick-dots {
                bottom: -10px;
            }
        }
    }
}

/* page catalog */

/* pagination */
.pagination {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
    ul {
        display: flex;
        flex-wrap: wrap;
        @include ul-default;
        li {
            margin: 0 0 10px 10px;
            a {
                display: flex;
                height: 35px;
                min-width: 35px;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                color: #373737;
                padding: 2px 5px;
                &:hover {
                    background-color: #002d72;
                    color: #fff;
                }
            }
            &.prev, &.next {
                a {
                    position: relative;
                    padding: 2px 17px;
                    &:after, &:before {
                        font-family: 'fontAwesome';
                    }
                }
            }
            &.prev {
                a:before {
                    content: '\f104';
                    margin-right: 10px;
                }
            }
            &.next {
                a:after {
                    content: '\f105';
                    margin-left: 10px;
                }
            }
            &.active a {
                background-color: #002d72;
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        ul {
            width: 100%;
            justify-content: center;
        }
    }
    @media screen and (max-width: 768px) {
        margin-top: 20px;
        line-height: 1;
        ul li {
            a {
                height: 30px;
                min-width: 30px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        ul li {
            &.prev,
            &.next {
                width: 100%;
                a {
                    width: 150px;
                    margin: 0 auto;
                }
            }
        }
    }
}
/* pagination */

/* page contacts */
.cts {
    display: flex;
    ul {
        @include ul-default;
        h5 {
            margin: 0 0 5px 0
        }
    }
    .col--location,
    .col--phone,
    .col--mob,
    .col--email {
        padding-left: 0;
        &:after {
            left: -22px;
        }
    }
    h3 {
        font-size: 26px;
        margin: 0 0 25px;
    }
    .left {
        width: calc(100% - 680px);
        padding-top: 35px;
        > ul > li {
            margin: 20px 0;
        }
    }
    .right {
        width: 680px;
    }
    textarea {
        margin-bottom: 30px;
    }
    .g-recaptcha {
        @include inline-block;
        transform: scale(0.8);
        transform-origin: 0 0;
    }
    .btn {
        height: 60px;
        width: 250px!important;
        float: right;
        padding: 20px 50px!important;
        margin-top: 0px;
    }
    @media screen and (max-width: 1200px) {
        .col--location,
        .col--phone,
        .col--mob,
        .col--email {
            padding-left: 20px;
            &:after {
                left: 0;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
        .left {
            width: calc(100% - 550px);
            padding-top: 0;
            > ul > li {
                margin-top: 0;
            }
        }
        .right {
            width: 550px;
        }
        textarea {
            margin-bottom: 15px;
        }
        .g-recaptcha {
            transform: scale(1);
            float: right;
        }
        .btn {
            height: 45px;
            padding: 15px 40px!important;
            margin-top: 15px;
        }
        h3 {
            font-size: 22px;
        }
    }
    @media screen and (max-width: 768px) {
        .left {
            width: calc(100% - 400px);
        }
        .right {
            width: 400px;
        }
        h3 {
            font-size: 20px;
            margin-bottom: 15px;
        }
    }
    @media screen and (max-width: 640px) {
        .left {
            width: 100%;
        }
        .right {
            width: 100%;
        }
    }
}
.callbackForm {
    padding: 0 15px 30px;
    @media screen and (max-width: 640px) {
        padding-left: 0;
        padding-right: 0;
        .g-recaptcha {
            float: right;
            transform: scale(0.92);
            transform-origin: 100% 0%;
        }
        .btn {
            width: auto!important;
            float: right;
            margin-top: 10px;
        }
    }
}
.map {
    margin: 50px 0 -80px;
    iframe, > ymaps, .ymaps-2-1-74-map {
        display: block;
        width: 100%!important;
        height: 500px!important;
    }
    @media screen and (max-width: 1440px) {
        iframe, > ymaps, .ymaps-2-1-74-map {
            height: 300px!important;
        }
    }
    @media screen and (max-width: 1024px) {
        margin: 35px 0 -50px;
    }
    @media screen and (max-width: 768px) {
        margin: 30px 0 -30px;
        iframe, > ymaps, .ymaps-2-1-74-map {
            height: 250px!important;
        }
    }
    @media screen and (max-width: 640px) {
        margin: 30px 0 -30px;
    }
}
/* page contacts */

/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 420px;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%);
        h4 {
            font-size: 20px;
            text-align: center;
            padding: 0 10px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 130px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            width: 100%;
            min-height: 50px;
            background-color: #d7d7d7;
            font-family: $regular;
            font-size: 18px;
            color: #353535;
            padding: 13px 20px;
            margin-bottom: 15px;
            box-shadow: none;
            border: none;
            line-height: 1;
            &::placeholder {
                color: #6f6f6f;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            padding-bottom: 17px!important;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
        }
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 45px;
                font-size: 16px;
                padding: 10px 15px;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 40px;
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
    }
    @media screen and (max-width: 380px) {
        form {
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 100% 50%;
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 100px;
    display: table;
    background-color: #2ac06d;
    font-family: 'FontAwesome';
    border-radius: 100%;
    text-decoration: none!important;
    width: 70px;
    height: 70px;
    text-align: center;
    padding: 20px 5px 5px 5px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
    box-sizing: border-box;
    outline: none!important;
    z-index: 1;
    &:hover {
        animation-name: phone;
        animation-iteration-count: 1;
        animation-duration: 0.7s;
    }
    i {
        display: block;
        font-size: 35px;
        color: #fff;
    }
    &:after, &:before  {
        content: '';
        width: 95%;
        height: 95%;
        background-color: transparent;
        border: 1px solid #2ac06d;
        position: absolute;
        top: 0%;
        left: 0%;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
        border: 1px solid #2ac06d;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0;
        right: 35px;
        bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 15px;
        right: 15px;
        bottom: 50px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        bottom: 30px;
    }
}

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

.btn--up {
    display: block;
    background-color: #e31e24;
    padding: 10px 15px;
    font-family: $bold;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    position: fixed;
    z-index: 1;
    right: 15px;
    bottom: 30px;
    line-height: 1;
    visibility: hidden;
    opacity: 0;
    transition: 500ms;
    &:focus {
        color: #fff;
    }
    &:hover {
        color: #fff;
        background-color: #e46064;
    }
    &.vis {
        opacity: 1;
        visibility: visible;
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
}

/* page content */