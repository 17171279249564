/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

@font-face {
    font-family: 'DINPro';
    src: url('../fonts/DINPro.eot');
    src: url('../fonts/DINPro.eot') format('embedded-opentype'),
         url('../fonts/DINPro.woff2') format('woff2'),
         url('../fonts/DINPro.woff') format('woff'),
         url('../fonts/DINPro.ttf') format('truetype'),
         url('../fonts/DINPro.svg#DINPro') format('svg');
}
@font-face {
    font-family: 'DINProBold';
    src: url('../fonts/DINProBold.eot');
    src: url('../fonts/DINProBold.eot') format('embedded-opentype'),
         url('../fonts/DINProBold.woff2') format('woff2'),
         url('../fonts/DINProBold.woff') format('woff'),
         url('../fonts/DINProBold.ttf') format('truetype'),
         url('../fonts/DINProBold.svg#DINProBold') format('svg');
}
@font-face {
    font-family: 'DINProLight';
    src: url('../fonts/DINProLight.eot');
    src: url('../onts/DINProLight.eot') format('embedded-opentype'),
         url('../fonts/DINProLight.woff2') format('woff2'),
         url('../fonts/DINProLight.woff') format('woff'),
         url('../fonts/DINProLight.ttf') format('truetype'),
         url('../fonts/DINProLight.svg#DINProLight') format('svg');
}
@font-face {
    font-family: 'DINProMedium';
    src: url('../fonts/DINProMedium.eot');
    src: url('../fonts/DINProMedium.eot') format('embedded-opentype'),
         url('../fonts/DINProMedium.woff2') format('woff2'),
         url('../fonts/DINProMedium.woff') format('woff'),
         url('../fonts/DINProMedium.ttf') format('truetype'),
         url('../fonts/DINProMedium.svg#DINProMedium') format('svg');
}
@font-face {
    font-family: 'DINProItalic';
    src: url('../fonts/DINProItalic.eot');
    src: url('../fonts/DINProItalic.eot') format('embedded-opentype'),
         url('../fonts/DINProItalic.woff2') format('woff2'),
         url('../fonts/DINProItalic.woff') format('woff'),
         url('../fonts/DINProItalic.ttf') format('truetype'),
         url('../fonts/DINProItalic.svg#DINProItalic') format('svg');
}
@font-face {
    font-family: 'DINProBlack';
    src: url('../fonts/DINProBlack.eot');
    src: url('../fonts/DINProBlack.eot') format('embedded-opentype'),
         url('../fonts/DINProBlack.woff2') format('woff2'),
         url('../fonts/DINProBlack.woff') format('woff'),
         url('../fonts/DINProBlack.ttf') format('truetype'),
         url('../fonts/DINProBlack.svg#DINProBlack') format('svg');
}

$regular: 'DINPro';
$bold: 'DINProBold';
$light: 'DINProLight';
$medium: 'DINProMedium';
$italic: 'DINProItalic';
$black: 'DINProBlack';

@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
    color: #353535;
	-webkit-text-size-adjust: 100%;
    opacity: 0;
	margin: 0;
    &[data-load="load"] {
        transition: 300ms;
        opacity: 1;
    }
    @media screen and (max-width: 1024px) {
        padding-top: 90px;
    }
    @media screen and (max-width: 768px) {
        display: block;
    }
}

h1 {
	font-family: $bold;
	font-size: 46px;
    font-weight: normal;
	color: #353535;
	line-height: 1;
	margin: 0 0 40px;
	@media screen and (max-width: 1200px) {
		font-size: 32px;
    }
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 30px;
        a {
            font-size: 28px
        }
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 20px;
        a {
            font-size: 24px;
        }
    }
}
h2 {
	font-family: $bold;
	font-size: 36px;
    font-weight: normal;
	color: #353535;
    margin: 0 0 40px;
	@media screen and (max-width: 1200px) {
		font-size: 30px;
		a {
			font-size: 30px;
		}
	}
	@media screen and (max-width: 1024px) {
		font-size: 26px;
        margin-bottom: 30px;
		a {
			font-size: 26px;
		}
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 20px;
    }
}
h3 {
	font-family: $bold;
	font-size: 24px;
    font-weight: normal;
	color: #353535;
	@media screen and (max-width: 1200px) {
		font-size: 22px;
	}
    @media screen and (max-width: 1024px) {
        font-size: 20px;
    }
    @media screen and (max-width: 768px) {
        font-size: 18px;
    }
}
h4 {
	font-family: $bold;
	font-size: 22px;
    font-weight: normal;
	color: #353535;
    @media screen and (max-width: 1200px) {
        font-size: 20px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 18px;
    }
    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
}
h5 {
	font-family: $bold;
	font-size: 18px;
    font-weight: normal;
	color: #353535;
    @media screen and (max-width: 1200px) {
        font-size: 16px;
    }
}
h6 {
	font-family: $bold;
	font-size: 16px;
    font-weight: normal;
	text-transform: uppercase;
	color: #353535;
    @media screen and (max-width: 1024px) {
        font-size: 14px;
    }
}
p {
    margin: 0 0 15px 0;
}
a {
    color: #353535;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #353535;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #002d72;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.main, .page {
	position: relative;
	flex: 1 0 auto;
    font-size: 18px;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        width: 100%;
        min-height: 50px;
		background-color: #d7d7d7;
		font-family: $regular;
		font-size: 18px;
        color: #353535;
		padding: 13px 20px;
        margin-bottom: 15px;
        box-shadow: none;
        border: none;
        line-height: 1;
        &::placeholder {
            color: #6f6f6f;
        }
	}
    @media screen and (max-width: 1440px) {
        font-size: 16px;
    }
    @media screen and (max-width: 1200px) {
        font-size: 16px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            font-size: 16px;
            min-height: 45px;
            padding: 10px 15px;
        }
    }
    @media screen and (max-width: 1024px) {
        font-size: 14px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 40px;
            font-size: 14px;
        }
    }
}
.main {
	.container {
		padding-top: 80px;
		padding-bottom: 80px;
		@media screen and (max-width: 1200px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 1024px) {
			padding-top: 35px;
			padding-bottom: 35px;
		}
        @media screen and (max-width: 768px) {
            padding-top: 20px;
            padding-bottom: 20px;
        }
	}
    h1, h2 {
        text-align: center;
    }
    h2 {
        color: #414141;
        a {
            color: #414141;
            &:hover {
                color: #002d72;
            }
        }
    }
}

.container {
    width: 1170px;
    padding: 0 15px;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
        width: 100%;
    }
}

.close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 25px;
        height: 2px;
        background-color: #004bb9;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
    height: 52px;
	width: auto!important;
	background-color: #004bb9!important;
	font-family: $bold!important;
	font-size: 18px;
	color: #fff!important;
    text-align: center;
	cursor: pointer;
	border-radius: 0px;
	border: none;
	padding: 15px 70px!important;
    line-height: 1;
	transition: 300ms;
	&:focus, &:active {
		background-color: #004bb9!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #3a64e1!important;
		color: #fff!important;
	}	
    &.light {
        background-color: #3a64e1!important;
        &:hover {
            background-color: #004bb9!important;
        }
    }
    @media screen and (max-width: 1366px) {
        height: 45px;
        padding: 12px 5px!important;
    }
    @media screen and (max-width: 1024px) {
        height: 45px;
        font-size: 16px;
        padding: 14px 50px!important;
    }
    @media screen and (max-width: 768px) {
        height: 40px;
        font-size: 14px;
        padding: 11px 40px 13px!important;
    }
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}

.col--location,
.col--phone,
.col--email,
.col--mob {
	position: relative;
	padding-left: 22px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 18px;
		color: #002d72;
		position: absolute;
		top: 2px;
		left: 0;
	}
    @media screen and (max-width: 1024px) {
        padding-left: 20px;
        &:after {
            font-size: 16px;
        }
    }
}
.col--location:after {
	content: '\f041';
}
.col--phone {
    &:after {
        content: '\f095';
    }
    // a {
    //     pointer-events: none;
    // }
    // @media screen and (max-width: 1024px) {
    //     a {
    //         pointer-events: auto;
    //     }
    // }
}
.col--email:after {
	content: '\f0e0';
	font-size: 14px;
	top: 4px;
    @media screen and (max-width: 1024px) {
        font-size: 12px;
    }
}
.col--mob:after {
    content: '\f10b';
    font-size: 20px;
    top: 0px;
}

/* header */
header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    line-height: 1;
    .container {
        height: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
    }
    ul {
        @include ul-default;
    }

	/* header phones */
	
	/* header phones */

	/* header logo */
    .logo {
        position: absolute;
        left: 15px;
        top: 5px;
        img {
            display: block;
        }
    }
	/* header logo */

	/* header nav */
	.nav {
        line-height: 1;
        margin-right: 85px;
        > ul {
            display: flex;
            > li {
                @include inline-block;
                margin: 0 10px;
                > a {
                    font-family: $medium;
                    font-size: 16px;
                    color: #fff;
                    text-transform: uppercase;
                    &:hover {
                        color: #fff;
                        text-decoration: underline;
                    }
                }
                &.active {
                    > a {
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
	/* header nav */

	/* header langs */
	.langs {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        padding-right: 15px;
        ul {
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            li {
                margin: 5px 0;
                a {
                    display: block;
                    width: 17px;
                    height: 17px;
                    overflow: hidden;
                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
        .langs__arr {
            right: 0;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            border-top: 6px solid #fff;
            border-left: 4.5px solid transparent;
            border-right: 4.5px solid transparent;
        }
        img {
            display: block;
            width: 17px;
        }
        .langs__title {
            display: block;
            width: 17px;
            height: 17px;
            border-radius: 100%;
            overflow: hidden;
        }
    }
	/* header langs */
    @media screen and (max-width: 1200px) {
        .logo {
            left: 15px;
            margin: 0;
        }
    }
    @media screen and (min-width: 1025px) {
        &.header {
            position: fixed;
            background-color: #fff;
            box-shadow: 0px 0px 25px rgba(0,0,0,0.7);
            .container {
                height: 80px;
            }
            .nav {
                > ul > li {
                    > a {
                        color: #353535;
                        &:hover {
                            color: #002d72;
                            text-decoration: none;
                        }
                    }
                    &.active > a {
                        color: #002d72;
                        text-decoration: none;
                    }
                }
            }
            .langs {
                .langs__arr {
                    border-top: 6px solid #2e2e2e;
                }
            }
        }
        .logo {
            width: 178px;
            img {
                width: 100%;
            }
        }
    }

	@media screen and (max-width: 1024px) {
        position: fixed;
        background-color: #002d71;
        .container {
            height: 90px;
        }
        .logo {
            position: absolute;
            width: 80px;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translateY(-50%);
            img {
                width: 100%;
            }
        }

        .nav {
            display: none;
            width: 100;
            max-height: calc(100% - 80px);
            background-color: #002d71;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            top: 90px;
            padding: 10px 15px;
            margin: 0;
            border-top: 1px solid #fff;
            border-bottom: 1px solid #fff;
            overflow: auto;
            > ul {
                flex-direction: column;
                > li {
                    margin: 8px 0;
                    > a {
                        display: table;
                        margin: 0 auto;
                    }
                }
            }
        }

		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 12px;
			top: 50%;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
            transform: translateY(-50%);
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #fff;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
        &.header {
            background-color: #fff;
            box-shadow: 0px 0px 15px #353535;
            .logo {
                width: 150px;
            }
            .nav--btn {
                span {
                    background-color: #353535;
                }
            }
            .nav {
                background-color: #fff;
                border-top: 1px solid #353535;
                border-bottom: 1px solid #353535;
                > ul > li {
                    > a {
                        color: #353535;
                        &:hover {
                            color: #e31e24;
                            text-decoration: none;
                        }
                    }
                    &.active > a {
                        color: #e31e24;
                        text-decoration: none;
                    }
                }
            }
            .langs {
                .langs__arr {
                    border-top: 6px solid #353535;
                }
            }
        }
	}
}
/* header */